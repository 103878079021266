interface IPatient {
    Active_Subscriptions__c: any;
    AddedBy__c: string;
    Address_1__c: string;
    Address_2__c: string;
    Age__c: any;
    Alt_ID__c: any;
    BHRT_Consent_Notes__c: any;
    BHRT_Consent_Required__c: boolean;
    BHRT_Consent__c: any;
    Billing_Dependent__c: any;
    Billing_Plan__c: any;
    City__c: string;
    Comprehensive_Notes__c: any;
    Country__c: string;
    CreatedById: string;
    CreatedDate: any;
    Current_Age__c: any;
    DOB_Text__c: any;
    DOB__c: any;
    Date_Added_Text__c: any;
    Date_Added__c: any;
    Dependent_Of__c: any;
    Email_Alternate__c: any;
    Email__c: any;
    Facial_Rejuvenation_Consent_Required__c: boolean;
    Family_History__c: any;
    Fax_Home__c: any;
    Fax_Work__c: any;
    First_Name__c: string;
    Focus_Notes__c: any;
    Freeze_Subscription_Notes__c: any;
    Freeze_Subscription__c: boolean;
    Gender_Identity__c: any;
    Gender__c: any;
    Hirani_A_La_Carte_Notes__c: any;
    Hirani_A_La_Carte__c: boolean;
    Home_Phone__c: any;
    Id: string;
    InActive_Date__c: any;
    Informed_Consent__c: any;
    Injection_Consent_Notes__c: any;
    Injection_Consent_Required__c: boolean;
    IsDeleted: boolean;
    LastActivityDate: any;
    LastModifiedById: any;
    LastModifiedDate: any;
    Last_Appointment_Date__c: any;
    Last_Appointment_Provider__c: any;
    Last_Appointment_Type_Text__c: any;
    Last_Appointment_Type__c: any;
    Last_Health_Coaching_Appt_Date__c: any;
    Last_Health_Coaching_Provider__c: any;
    Last_Name__c: string;
    Maintenance_Notes__c: any;
    Medical_History__c: any;
    Medicare_Beneficiary_Notes__c: any;
    Medicare_Beneficiary_Required__c: boolean;
    Medicare_Beneficiary__c: any;
    Middle_Name__c: any;
    Missing_Form_Names__c: any;
    Missing_Forms_Test__c: any;
    Missing_Forms__c: any;
    Mobile_Phone__c: any;
    Name: string;
    New_Contract_Signed_Notes__c: any;
    New_Contract_Signed__c: boolean;
    Next_Appointment_Date__c: any;
    Next_Appointment_Provider__c: any;
    Next_Appointment_Type_Text__c: any;
    Next_Appointment_Type__c: any;
    Next_Health_Coaching_Appt_Date__c: any;
    Next_Health_Coaching_Provider__c: any;
    Nickname__c: any;
    No_TeleHealth_Notes__c: any;
    No_TeleHealth__c: boolean;
    Not_Primary_Care_Hirani__c: any;
    Number_of_Blood_Pressure_Readings__c: number;
    Number_of_Dr_Messages__c: number;
    Number_of_Encounters__c: number;
    Number_of_Height_Readings__c: number;
    Number_of_Invoices__c: number;
    Number_of_Patient_Messages__c: number;
    Number_of_Patient_Orders__c: number;
    Number_of_Questionnaires__c: number;
    Number_of_Rx__c: number;
    Number_of_Supplements__c: number;
    Number_of_Weight_Readings__c: number;
    Other_Phone__c: any;
    OwnerId: string;
    PHI_Disclosure_Notes__c: any;
    PHI_Disclosure_Required__c: boolean;
    PHI_Disclosure__c: any;
    Patient_Create_Date__c: any;
    Patient_Name__c: string;
    Patient_Questionnaire_Email_Sent_Date__c: any;
    Patients__c: any;
    Payment_Frequency__c: any;
    Payment_Plan_Notes__c: any;
    Payment_Plan__c: boolean;
    Portal_Access__c: boolean;
    Premier_Notes__c: any;
    Primary_Phone__c: any;
    Primary_Provider_Id_Text__c: any;
    Primary_Provider_Id__c: string;
    Privacy_Policy_Filled__c: boolean;
    Privacy_Policy__c: any;
    Prospective_Patient_Notes__c: any;
    Prospective_Patient_flag__c: boolean;
    Provider_Name__c: string;
    Readiness_Assessment__c: any;
    Sex__c: string;
    Social_History__c: any;
    Source__c: string;
    State__c: any;
    Status__c: string;
    Subscription_Date_Applied__c: any;
    Subscription_End_Date__c: any;
    Subscription_Notes__c: any;
    Subscription_Plan__c: any;
    Subscription_Start_Date__c: any;
    Subscription_Usage_Notes__c: any;
    Subscription__c: boolean;
    SystemModstamp: any;
    TeleHealth_Required__c: boolean;
    TeleMedicine_Consent__c: any;
    Test_Patient__c: boolean;
    Work_Phone__c: any;
    Zip__c: any;
    TimeZone__c:any;
}
export class Patient {
    private Id: string;
    private Active_Subscriptions__c: any;
    private AddedBy__c: string;
    private Address_1__c: string;
    private Address_2__c: string;
    private Age__c: any;
    private Alt_ID__c: any;
    private BHRT_Consent_Notes__c: any;
    private BHRT_Consent_Required__c: boolean;
    private BHRT_Consent__c: any;
    private Billing_Dependent__c: any;
    private Billing_Plan__c: any;
    private City__c: string;
    private Comprehensive_Notes__c: any;
    private Country__c: string;
    private CreatedById: string;
    private CreatedDate: any;
    private Current_Age__c: any;
    private DOB_Text__c: any;
    private DOB__c: any;
    private Date_Added_Text__c: any;
    private Date_Added__c: any;
    private Dependent_Of__c: any;
    private Email_Alternate__c: any;
    private Email__c: any;
    private Facial_Rejuvenation_Consent_Required__c: boolean;
    private Family_History__c: any;
    private Fax_Home__c: any;
    private Fax_Work__c: any;
    private First_Name__c: string;
    private Focus_Notes__c: any;
    private Freeze_Subscription_Notes__c: any;
    private Freeze_Subscription__c: boolean;
    private Gender_Identity__c: any;
    private Gender__c: any;
    private Hirani_A_La_Carte_Notes__c: any;
    private Hirani_A_La_Carte__c: boolean;
    private Home_Phone__c: any;
    private InActive_Date__c: any;
    private Informed_Consent__c: any;
    private Injection_Consent_Notes__c: any;
    private Injection_Consent_Required__c: boolean;
    private IsDeleted: boolean;
    private LastActivityDate: any;
    private LastModifiedById: any;
    private LastModifiedDate: any;
    private Last_Appointment_Date__c: any;
    private Last_Appointment_Provider__c: any;
    private Last_Appointment_Type_Text__c: any;
    private Last_Appointment_Type__c: any;
    private Last_Health_Coaching_Appt_Date__c: any;
    private Last_Health_Coaching_Provider__c: any;
    private Last_Name__c: string;
    private Maintenance_Notes__c: any;
    private Medical_History__c: any;
    private Medicare_Beneficiary_Notes__c: any;
    private Medicare_Beneficiary_Required__c: boolean;
    private Medicare_Beneficiary__c: any;
    private Middle_Name__c: any;
    private Missing_Form_Names__c: any;
    private Missing_Forms_Test__c: any;
    private Missing_Forms__c: any;
    private Mobile_Phone__c: any;
    private Name: string;
    private New_Contract_Signed_Notes__c: any;
    private New_Contract_Signed__c: boolean;
    private Next_Appointment_Date__c: any;
    private Next_Appointment_Provider__c: any;
    private Next_Appointment_Type_Text__c: any;
    private Next_Appointment_Type__c: any;
    private Next_Health_Coaching_Appt_Date__c: any;
    private Next_Health_Coaching_Provider__c: any;
    private Nickname__c: any;
    private No_TeleHealth_Notes__c: any;
    private No_TeleHealth__c: boolean;
    private Not_Primary_Care_Hirani__c: any;
    private Number_of_Blood_Pressure_Readings__c: number;
    private Number_of_Dr_Messages__c: number;
    private Number_of_Encounters__c: number;
    private Number_of_Height_Readings__c: number;
    private Number_of_Invoices__c: number;
    private Number_of_Patient_Messages__c: number;
    private Number_of_Patient_Orders__c: number;
    private Number_of_Questionnaires__c: number;
    private Number_of_Rx__c: number;
    private Number_of_Supplements__c: number;
    private Number_of_Weight_Readings__c: number;
    private Other_Phone__c: any;
    private OwnerId: string;
    private PHI_Disclosure_Notes__c: any;
    private PHI_Disclosure_Required__c: boolean;
    private PHI_Disclosure__c: any;
    private Patient_Create_Date__c: any;
    private Patient_Name__c: string;
    private Patient_Questionnaire_Email_Sent_Date__c: any;
    private Patients__c: any;
    private Payment_Frequency__c: any;
    private Payment_Plan_Notes__c: any;
    private Payment_Plan__c: boolean;
    private Portal_Access__c: boolean;
    private Premier_Notes__c: any;
    private Primary_Phone__c: any;
    private Primary_Provider_Id_Text__c: any;
    private Primary_Provider_Id__c: string;
    private Privacy_Policy_Filled__c: boolean;
    private Privacy_Policy__c: any;
    private Prospective_Patient_Notes__c: any;
    private Prospective_Patient_flag__c: boolean;
    private Provider_Name__c: string;
    private Readiness_Assessment__c: any;
    private Sex__c: string;
    private Social_History__c: any;
    private Source__c: string;
    private State__c: any;
    private Status__c: string;
    private Subscription_Date_Applied__c: any;
    private Subscription_End_Date__c: any;
    private Subscription_Notes__c: any;
    private Subscription_Plan__c: any;
    private Subscription_Start_Date__c: any;
    private Subscription_Usage_Notes__c: any;
    private Subscription__c: boolean;
    private SystemModstamp: any;
    private TeleHealth_Required__c: boolean;
    private TeleMedicine_Consent__c: any;
    private Test_Patient__c: boolean;
    private Work_Phone__c: any;
    private Zip__c: any;
    private TimeZone__c:any;

    constructor({ ...options }: IPatient) {
        this.set(options);
    }

    set<T extends IPatient>({ ...options }: T): void {
        this.Id = options.Id;
        this.Active_Subscriptions__c = options.Active_Subscriptions__c;
        this.AddedBy__c = options.AddedBy__c;
        this.Address_1__c = options.Address_1__c;
        this.Address_2__c = options.Address_2__c;
        this.Age__c = options.Age__c;
        this.Alt_ID__c = options.Alt_ID__c;
        this.BHRT_Consent_Notes__c = options.BHRT_Consent_Notes__c;
        this.BHRT_Consent_Required__c = options.BHRT_Consent_Required__c;
        this.BHRT_Consent__c = options.BHRT_Consent__c;
        this.Billing_Dependent__c = options.Billing_Dependent__c;
        this.Billing_Plan__c = options.Billing_Plan__c;
        this.City__c = options.City__c;
        this.Comprehensive_Notes__c = options.Comprehensive_Notes__c;
        this.Country__c = options.Country__c;
        this.CreatedById = options.CreatedById;
        this.CreatedDate = options.CreatedDate;
        this.Current_Age__c = options.Current_Age__c;
        this.DOB_Text__c = options.DOB_Text__c;
        this.DOB__c = options.DOB__c;
        this.Date_Added_Text__c = options.Date_Added_Text__c;
        this.Date_Added__c = options.Date_Added__c;
        this.Dependent_Of__c = options.Dependent_Of__c;
        this.Email_Alternate__c = options.Email_Alternate__c;
        this.Email__c = options.Email__c;
        this.Facial_Rejuvenation_Consent_Required__c = options.Facial_Rejuvenation_Consent_Required__c;
        this.Family_History__c = options.Family_History__c;
        this.Fax_Home__c = options.Fax_Home__c;
        this.Fax_Work__c = options.Fax_Work__c;
        this.First_Name__c = options.First_Name__c;
        this.Focus_Notes__c = options.Focus_Notes__c;
        this.Freeze_Subscription_Notes__c = options.Freeze_Subscription_Notes__c;
        this.Freeze_Subscription__c = options.Freeze_Subscription__c;
        this.Gender_Identity__c = options.Gender_Identity__c;
        this.Gender__c = options.Gender__c;
        this.Hirani_A_La_Carte_Notes__c = options.Hirani_A_La_Carte_Notes__c;
        this.Hirani_A_La_Carte__c = options.Hirani_A_La_Carte__c;
        this.Home_Phone__c = options.Home_Phone__c;
        this.InActive_Date__c = options.InActive_Date__c;
        this.Informed_Consent__c = options.Informed_Consent__c;
        this.Injection_Consent_Notes__c = options.Injection_Consent_Notes__c;
        this.Injection_Consent_Required__c = options.Injection_Consent_Required__c;
        this.IsDeleted = options.IsDeleted;
        this.LastActivityDate = options.LastActivityDate;
        this.LastModifiedById = options.LastModifiedById;
        this.LastModifiedDate = options.LastModifiedDate;
        this.Last_Appointment_Date__c = options.Last_Appointment_Date__c;
        this.Last_Appointment_Provider__c = options.Last_Appointment_Provider__c;
        this.Last_Appointment_Type_Text__c = options.Last_Appointment_Type_Text__c;
        this.Last_Appointment_Type__c = options.Last_Appointment_Type__c;
        this.Last_Health_Coaching_Appt_Date__c = options.Last_Health_Coaching_Appt_Date__c;
        this.Last_Health_Coaching_Provider__c = options.Last_Health_Coaching_Provider__c;
        this.Last_Name__c = options.Last_Name__c;
        this.Maintenance_Notes__c = options.Maintenance_Notes__c;
        this.Medical_History__c = options.Medical_History__c;
        this.Medicare_Beneficiary_Notes__c = options.Medicare_Beneficiary_Notes__c;
        this.Medicare_Beneficiary_Required__c = options.Medicare_Beneficiary_Required__c;
        this.Medicare_Beneficiary__c = options.Medicare_Beneficiary__c;
        this.Middle_Name__c = options.Middle_Name__c;
        this.Missing_Form_Names__c = options.Missing_Form_Names__c;
        this.Missing_Forms_Test__c = options.Missing_Forms_Test__c;
        this.Missing_Forms__c = options.Missing_Forms__c;
        this.Mobile_Phone__c = options.Mobile_Phone__c;
        this.Name = options.Name;
        this.New_Contract_Signed_Notes__c = options.New_Contract_Signed_Notes__c;
        this.New_Contract_Signed__c = options.New_Contract_Signed__c;
        this.Next_Appointment_Date__c = options.Next_Appointment_Date__c;
        this.Next_Appointment_Provider__c = options.Next_Appointment_Provider__c;
        this.Next_Appointment_Type_Text__c = options.Next_Appointment_Type_Text__c;
        this.Next_Appointment_Type__c = options.Next_Appointment_Type__c;
        this.Next_Health_Coaching_Appt_Date__c = options.Next_Health_Coaching_Appt_Date__c;
        this.Next_Health_Coaching_Provider__c = options.Next_Health_Coaching_Provider__c;
        this.Nickname__c = options.Nickname__c;
        this.No_TeleHealth_Notes__c = options.No_TeleHealth_Notes__c;
        this.No_TeleHealth__c = options.No_TeleHealth__c;
        this.Not_Primary_Care_Hirani__c = options.Not_Primary_Care_Hirani__c;
        this.Number_of_Blood_Pressure_Readings__c = options.Number_of_Blood_Pressure_Readings__c;
        this.Number_of_Dr_Messages__c = options.Number_of_Dr_Messages__c;
        this.Number_of_Encounters__c = options.Number_of_Encounters__c;
        this.Number_of_Height_Readings__c = options.Number_of_Height_Readings__c;
        this.Number_of_Invoices__c = options.Number_of_Invoices__c;
        this.Number_of_Patient_Messages__c = options.Number_of_Patient_Messages__c;
        this.Number_of_Patient_Orders__c = options.Number_of_Patient_Orders__c;
        this.Number_of_Questionnaires__c = options.Number_of_Questionnaires__c;
        this.Number_of_Rx__c = options.Number_of_Rx__c;
        this.Number_of_Supplements__c = options.Number_of_Supplements__c;
        this.Number_of_Weight_Readings__c = options.Number_of_Weight_Readings__c;
        this.Other_Phone__c = options.Other_Phone__c;
        this.OwnerId = options.OwnerId;
        this.PHI_Disclosure_Notes__c = options.PHI_Disclosure_Notes__c;
        this.PHI_Disclosure_Required__c = options.PHI_Disclosure_Required__c;
        this.PHI_Disclosure__c = options.PHI_Disclosure__c;
        this.Patient_Create_Date__c = options.Patient_Create_Date__c;
        this.Patient_Name__c = options.Patient_Name__c;
        this.Patient_Questionnaire_Email_Sent_Date__c = options.Patient_Questionnaire_Email_Sent_Date__c;
        this.Patients__c = options.Patients__c;
        this.Payment_Frequency__c = options.Payment_Frequency__c;
        this.Payment_Plan_Notes__c = options.Payment_Plan_Notes__c;
        this.Payment_Plan__c = options.Payment_Plan__c;
        this.Portal_Access__c = options.Portal_Access__c;
        this.Premier_Notes__c = options.Premier_Notes__c;
        this.Primary_Phone__c = options.Primary_Phone__c;
        this.Primary_Provider_Id_Text__c = options.Primary_Provider_Id_Text__c;
        this.Primary_Provider_Id__c = options.Primary_Provider_Id__c;
        this.Privacy_Policy_Filled__c = options.Privacy_Policy_Filled__c;
        this.Privacy_Policy__c = options.Privacy_Policy__c;
        this.Prospective_Patient_Notes__c = options.Prospective_Patient_Notes__c;
        this.Prospective_Patient_flag__c = options.Prospective_Patient_flag__c;
        this.Provider_Name__c = options.Provider_Name__c;
        this.Readiness_Assessment__c = options.Readiness_Assessment__c;
        this.Sex__c = options.Sex__c;
        this.Social_History__c = options.Social_History__c;
        this.Source__c = options.Source__c;
        this.State__c = options.State__c;
        this.Status__c = options.Status__c;
        this.Subscription_Date_Applied__c = options.Subscription_Date_Applied__c;
        this.Subscription_End_Date__c = options.Subscription_End_Date__c;
        this.Subscription_Notes__c = options.Subscription_Notes__c;
        this.Subscription_Plan__c = options.Subscription_Plan__c;
        this.Subscription_Start_Date__c = options.Subscription_Start_Date__c;
        this.Subscription_Usage_Notes__c = options.Subscription_Usage_Notes__c;
        this.Subscription__c = options.Subscription__c;
        this.SystemModstamp = options.SystemModstamp;
        this.TeleHealth_Required__c = options.TeleHealth_Required__c;
        this.TeleMedicine_Consent__c = options.TeleMedicine_Consent__c;
        this.Test_Patient__c = options.Test_Patient__c;
        this.Work_Phone__c = options.Work_Phone__c;
        this.Zip__c = options.Zip__c;
        this. TimeZone__c = options.TimeZone__c;
    }

    public id() {
        return this.Id;
    }

    public activeSubscriptions() {
        return this.Active_Subscriptions__c;
    }

    public address1() {
        return this.Address_1__c;
    }

    public address2() {
        return this.Address_2__c;
    }

    public addressCombine() {
        return this.Address_1__c + ' ' + this.Address_2__c;
    }

    public email() {
        return this.Email__c;
    }
    public emailAlternate() {
        return this.Email_Alternate__c;
    }

    public firstName() {
        return this.First_Name__c;
    }

    public lastName() {
        return this.Last_Name__c;
    }
    public middleName() {
        return this.Middle_Name__c;
    }
    public fullName() {
        return  this.First_Name__c + " " + this.Middle_Name__c + " " + this.Last_Name__c;
    }

    public name() {
        return this.Name; // e.g "2141"
    }
    public addedBy() {
        return this.AddedBy__c;
    }
    public age() {
        return this.Age__c;
    }
    public currentAge() {
        return this.Current_Age__c;
    }
    public altId() {
        return this.Alt_ID__c;
    }
    public BHRTConsentNotes() {
        return this.BHRT_Consent_Notes__c;
    }

    public BHRTConsentRequired() {
        return this.BHRT_Consent_Required__c;
    }
    public BHRTConsent() {
        return this.BHRT_Consent__c;
    }
    public billingDependent() {
        return this.Billing_Dependent__c;
    }
    public billingPlan() {
        return this.Billing_Plan__c;
    }
    public city() {
        return this.City__c;
    }
    public comprehensiveNotes() {
        return this.Comprehensive_Notes__c;
    }
    public country() {
        return this.Country__c;
    }
    public createdById() {
        return this.CreatedById;
    }
    public createdDate() {
        return this.CreatedDate;
    }
    public dobText() {
        return this.DOB_Text__c;
    }
    public dob() {
        return this.DOB__c;
    }
    public dateAddedText() {
        return this.Date_Added_Text__c;
    }
    public dateAdded() {
        return this.Date_Added__c;
    }
    public dependentOf() {
        return this.Dependent_Of__c;
    }
    public facialRejuvenationConsentRequired() {
        return this.Facial_Rejuvenation_Consent_Required__c;
    }
    public familyHistory() {
        return this.Family_History__c;
    }
    public faxHome() {
        return this.Fax_Home__c;
    }
    public faxWork() {
        return this.Fax_Work__c;
    }
    public focusNotes() {
        return this.Focus_Notes__c;
    }
    public freezeSubscriptionNotes() {
        return this.Freeze_Subscription_Notes__c;
    }
    public freezeSubscription() {
        return this.Freeze_Subscription__c;
    }
    public genderIdentity() {
        return this.Gender_Identity__c;
    }
    public gender() {
        return this.Gender__c;
    }
    public hiraniALaCarteNotes() {
        return this.Hirani_A_La_Carte_Notes__c;
    }
    public hiraniALaCarte() {
        return this.Hirani_A_La_Carte__c;
    }
    public homePhone() {
        return this.Home_Phone__c;
    }
    public inActiveDate() {
        return this.InActive_Date__c;
    }
    public informedConsent() {
        return this.Informed_Consent__c;
    }
    public injectionConsentNotes() {
        return this.Injection_Consent_Notes__c;
    }
    public injectionConsentRequired() {
        return this.Injection_Consent_Required__c;
    }
    public isDeleted() {
        return this.IsDeleted;
    }
    public lastActivityDate() {
        return this.LastActivityDate;
    }
    public lastModifiedById() {
        return this.LastModifiedById;
    }
    public lastModifiedDate() {
        return this.LastModifiedDate;
    }
    public lastAppointmentDate() {
        return this.Last_Appointment_Date__c;
    }
    public lastAppointmentProvider() {
        return this.Last_Appointment_Provider__c;
    }
    public lastAppointmentTypeText() {
        return this.Last_Appointment_Type_Text__c;
    }
    public lastAppointmentType() {
        return this.Last_Appointment_Type__c;
    }
    public lastHealthCoachingApptDate() {
        return this.Last_Health_Coaching_Appt_Date__c;
    }
    public lastHealthCoachingProvider() {
        return this.Last_Health_Coaching_Provider__c;
    }
    public maintenanceNotes() {
        return this.Maintenance_Notes__c;
    }
    public medicalHistory() {
        return this.Medical_History__c;
    }
    public medicareBeneficiaryNotes() {
        return this.Medicare_Beneficiary_Notes__c;
    }
    public medicareBeneficiaryRequired() {
        return this.Medicare_Beneficiary_Required__c;
    }
    public medicareBeneficiary() {
        return this.Medicare_Beneficiary__c;
    }
    public missingFormNames() {
        return this.Missing_Form_Names__c;
    }
    public missingFormsTest() {
        return this.Missing_Forms_Test__c;
    }
    public missingForms() {
        return this.Missing_Forms__c;
    }
    public mobilePhone() {
        return this.Mobile_Phone__c;
    }
    public newContractSignedNotes() {
        return this.New_Contract_Signed_Notes__c;
    }
    public newContractSigned() {
        return this.New_Contract_Signed__c;
    }
    public nextAppointmentDate() {
        return this.Next_Appointment_Date__c;
    }
    public nextAppointmentProvider() {
        return this.Next_Appointment_Provider__c;
    }
    public nextAppointmentTypeText() {
        return this.Next_Appointment_Type_Text__c;
    }
    public nextAppointmentType() {
        return this.Next_Appointment_Type__c;
    }
    public nextHealthCoachingApptDate() {
        return this.Next_Health_Coaching_Appt_Date__c;
    }
    public nextHealthCoachingProvider() {
        return this.Next_Health_Coaching_Provider__c;
    }
    public nickname() {
        return this.Nickname__c;
    }
    public noTeleHealthNotes() {
        return this.No_TeleHealth_Notes__c;
    }
    public noTeleHealth() {
        return this.No_TeleHealth__c;
    }
    public notPrimaryCareHirani() {
        return this.Not_Primary_Care_Hirani__c;
    }
    public numberOfBloodPressureReadings() {
        return this.Number_of_Blood_Pressure_Readings__c;
    }
    public numberOfDrMessages() {
        return this.Number_of_Dr_Messages__c;
    }
    public numberOfEncounters() {
        return this.Number_of_Encounters__c;
    }
    public numberOfHeightReadings() {
        return this.Number_of_Height_Readings__c;
    }
    public numberOfInvoices() {
        return this.Number_of_Invoices__c;
    }
    public numberOfPatientMessages() {
        return this.Number_of_Patient_Messages__c;
    }
    public numberOfPatientOrders() {
        return this.Number_of_Patient_Orders__c;
    }
    public numberOfQuestionnaires() {
        return this.Number_of_Questionnaires__c;
    }
    public numberOfRx() {
        return this.Number_of_Rx__c;
    }
    public numberOfSupplements() {
        return this.Number_of_Supplements__c;
    }
    public numberOfWeightReadings() {
        return this.Number_of_Weight_Readings__c;
    }
    public otherPhone() {
        return this.Other_Phone__c;
    }
    public ownerId() {
        return this.OwnerId;
    }
    public PHIDisclosureNotes() {
        return this.PHI_Disclosure_Notes__c;
    }
    public PHIDisclosureRequired() {
        return this.PHI_Disclosure_Required__c;
    }
    public PHIDisclosure() {
        return this.PHI_Disclosure__c;
    }
    public patientCreateDate() {
        return this.Patient_Create_Date__c;
    }
    public patientName() {
        return this.Patient_Name__c;
    }
    public patientQuestionnaireEmailSentDate() {
        return this.Patient_Questionnaire_Email_Sent_Date__c;
    }
    public patients() {
        return this.Patients__c;
    }
    public paymentFrequency() {
        return this.Payment_Frequency__c;
    }
    public paymentPlanNotes() {
        return this.Payment_Plan_Notes__c;
    }
    public paymentPlan() {
        return this.Payment_Plan__c;
    }
    public portalAccess() {
        return this.Portal_Access__c;
    }
    public premierNotes() {
        return this.Premier_Notes__c;
    }
    public primaryPhone() {
        return this.Primary_Phone__c;
    }
    public primaryProviderIdText() {
        return this.Primary_Provider_Id_Text__c;
    }
    public primaryProviderId() {
        return this.Primary_Provider_Id__c;
    }
    public privacyPolicyFilled() {
        return this.Privacy_Policy_Filled__c;
    }
    public privacyPolicy() {
        return this.Privacy_Policy__c;
    }
    public prospectivePatientNotes() {
        return this.Prospective_Patient_Notes__c;
    }
    public prospectivePatientFlag() {
        return this.Prospective_Patient_flag__c;
    }
    public providerName() {
        return this.Provider_Name__c;
    }
    public readinessAssessment() {
        return this.Readiness_Assessment__c;
    }
    public sex() {
        return this.Sex__c;
    }
    public socialHistory() {
        return this.Social_History__c;
    }
    public source() {
        return this.Source__c;
    }
    public state() {
        return this.State__c;
    }
    public status() {
        return this.Status__c;
    }
    public subscriptionDateApplied() {
        return this.Subscription_Date_Applied__c;
    }
    public subscriptionEndDate() {
        return this.Subscription_End_Date__c;
    }
    public subscriptionNotes() {
        return this.Subscription_Notes__c;
    }
    public subscriptionPlan() {
        return this.Subscription_Plan__c;
    }
    public subscriptionStartDate() {
        return this.Subscription_Start_Date__c;
    }
    public subscriptionUsageNotes() {
        return this.Subscription_Usage_Notes__c;
    }
    public subscription() {
        return this.Subscription__c;
    }
    public systemModstamp() {
        return this.SystemModstamp;
    }
    public teleHealthRequired() {
        return this.TeleHealth_Required__c;
    }
    public teleMedicineConsent() {
        return this.TeleMedicine_Consent__c;
    }
    public testPatient() {
        return this.Test_Patient__c;
    }
    public WorkPhone() {
        return this.Work_Phone__c;
    }
    public zip() {
        return this.Zip__c;
    } 

    public timeZone() {
        return this.TimeZone__c;
    } 
       
}
