import { Injectable } from '@angular/core';
import * as moment from 'moment';
 
import { Task } from '../model';
import { environment } from 'src/environments/environment';
/*
* This service maintain entire score of user.
*/

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private static _task: Task = new Task();

  constructor() { }

  public static task(val?: any) {
    return val ? TaskService._task.userTask(val) : TaskService._task;
  }

  public static nMonthsBackDate(n) {
    return moment().subtract(n, 'months').startOf('day').format();
  }

  public static nMonthsNextDate(n) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setMonth(date.getMonth() + n);
    return date.toISOString();
  }

  public static logout(router) {
    // clear all local/session variables
    localStorage.removeItem('user');
    localStorage.removeItem('loginUserRole');
    sessionStorage.removeItem('userData');

    //Clear task obj
    TaskService._task = new Task();

    // Redirect to login and refresh page to get updated build code
    router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  public static storeForm(id, data) {
    localStorage.setItem(id, JSON.stringify(data));
  }

  public static getCachedFormData(id) {
    return localStorage.getItem(id) ? JSON.parse(localStorage.getItem(id)) : null;
  }

  public static timZoneChange(){
    //console.log("TaskService.task().userTask()", TaskService.task().userTask())
    if(TaskService.task().userTask()  != undefined){
    let timeZone = TaskService.task().userTask().TimeZone__c;
     //console.log("timeZone", timeZone);
   
    if(timeZone != null){
      let timeZ = timeZone.split(' ');
      let timeZvalue = timeZ[0].replace(/\s|\(|\)/g,"");
    //  console.log("......Time132.........", timeZvalue)
      return timeZvalue;
    }else{
      return;
    }
  }
  
  }

  public static dateFormat(){
    let dateFromat = 'MM/dd/yyyy hh:mm a'
    return dateFromat;

  }

  public static dateFormat2Digit(){
    let dateFromat = 'MM/dd/yy hh:mm a'
    return dateFromat;

  }

  public static onlyDateFormat(){
    let dateFromat = 'MM/dd/yyyy'
    return dateFromat;

  }

  public static isProdEnv() {
    return environment.IS_PROD;
  }
}
