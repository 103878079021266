import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
// Declare this key and iv values in declaration
const key = CryptoJS.enc.Utf8.parse('test');
const iv = CryptoJS.enc.Utf8.parse('test123');

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }

  public static encryptUsingAES256(data: string) {
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  public static decryptUsingAES256(decString) {
    var decrypted = CryptoJS.AES.decrypt(decString, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
