import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from '../../model/user.model';
import { CryptoService } from '../crytoservice/crypto-service.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedinUserService {
  private static _user: User = new User();
  userData = new Subject<User>();

  constructor(private router: Router) {}

  // Getter for user data
  get user() {
    return LoggedinUserService._user;
  }

  // Setter for user data
  set user(userData: User) {
    if (userData.clientId) {
      const data = userData.getUserData();
      localStorage.setItem(
        'user',
        CryptoService.encryptUsingAES256(JSON.stringify(data))
      );
      LoggedinUserService._user = userData;
      this.userData.next(userData);
    } else {
      this.logout();
    }
  }

  isUserDataAvailable() {
    const isDataAvailable = Object.values(LoggedinUserService._user).some(
      (val) => val
    );
    return isDataAvailable;
  }

  logout() {
    // clear all local/session variables
    localStorage.removeItem('user');

    //Clear task obj
    LoggedinUserService._user = new User();

    // remove session
    localStorage.removeItem('session');

    // Redirect to login and refresh page to get updated build code
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
}
