import { Patient } from "./patient.model";
import { Provider } from "./provider.model";

interface ITask {
    userTask:any
}
export class Task {
    private _userTask:any;
    private _loginAs:string;
    private _isPatient:boolean;

    // constructor( {...options}: ITask) {
    //     this.set(options);
    // }

    // set<T extends ITask>({...options}: T): void {
    //     this._task = options.task;
    // }
    

    public userTask(value:any) {
        const t = this._isPatient ? new Patient(value) : new Provider(value);
        return  value ? this._userTask = t : this._userTask;
    }

    public loginAs(value:any) {
        return  value ? this._loginAs = value : this._loginAs;
    }
    public isPatient(val:boolean) {
        return  val ? this._isPatient = val : this._isPatient;
    }
}
