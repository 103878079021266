export interface ISession {
    accessToken: string;
    refreshToken: string;
    accessTokenExp: string;
    refreshTokenExp: string;
}

export class Session {
    private _accessToken: string = '';
    private _refreshToken: string = '';
    private _accessTokenExp: string = '';
    private _refreshTokenExp: string = '';

    constructor(options?: ISession) {
        if (options) {
            this.set(options);
        }
    }

    set<T extends ISession>({ ...options }: T): void {
        this._accessToken = options.accessToken;
        this._refreshToken = options.refreshToken;
        this._accessTokenExp = options.accessTokenExp;
        this._refreshTokenExp = options.refreshTokenExp;
    }

    // All getter and setters
    get accessToken() {
        return this._accessToken;
    }

    set accessToken(data: string) {
        this._accessToken = data;
    }

    get refreshToken() {
        return this._refreshToken;
    }

    set refreshToken(data: string) {
        this._refreshToken = data;
    }

    get accessTokenExp() {
        return this._accessTokenExp;
    }

    set accessTokenExp(data: string) {
        this._accessTokenExp = data;
    }

    get refreshTokenExp() {
        return this._refreshTokenExp;
    }

    set refreshTokenExp(data: string) {
        this._refreshTokenExp = data;
    }

    getSessionData() {
        return {
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            accessTokenExp: this.accessTokenExp,
            refreshTokenExp: this.refreshTokenExp,
        };
    }
}
