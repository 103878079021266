import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TaskService } from '../shared/services/task.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router
) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // todo
      return true;
      const taskObj = TaskService.task() && (TaskService.task().userTask() || localStorage.getItem('user'));
      if (taskObj) {
        return true;
      }

      this.router.navigate(['/']);
      return false;
  }
  
}
