export interface IUser {
    clientId: string;
    userId: string;
    username: string;
    phone: string;
    twoFA: string;
}

export class User {
    private _clientId: string = '';
    private _userId: string = '';
    private _username: string = '';
    private _phone: string = '';
    private _twoFA: string = '';

    constructor(options?: IUser) {
        if (options) {
            this.set(options);
        }
    }

    set<T extends IUser>({ ...options }: T): void {
        this._clientId = options.clientId;
        this._userId = options.userId;
        this._username = options.username;
        this._phone = options.phone;
        this._twoFA = options.twoFA;
    }

    // All getter and setters
    get clientId() {
        return this._clientId;
    }

    set clientId(data: string) {
        this._clientId = data;
    }

    get userId() {
        return this._userId;
    }

    set userId(data: string) {
        this._userId = data;
    }

    get username() {
        return this._username;
    }

    set username(data: string) {
        this._username = data;
    }

    get phone() {
        return this._phone;
    }

    set phone(data: string) {
        this._phone = data;
    }

    get twoFA() {
        return this._twoFA;
    }

    set twoFA(data: string) {
        this._twoFA = data;
    }

    getUserData() {
        return {
            clientId: this.clientId,
            userId: this.userId,
            username: this.username,
            phone: this._phone,
            twoFA: this._twoFA,
        };
    }
}
