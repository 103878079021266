import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2CompleterModule } from "ng2-completer";
import { QuillModule } from 'ngx-quill';
import { OverlayModule } from '@angular/cdk/overlay';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { OneTimeBindingDirective } from './directives/one-time-binding.directive';
import { CsvUploadComponent } from './components/csv-upload/csv-upload.component';


@NgModule({
  declarations: [
    SpinnerComponent,    
    CustomTooltipDirective,
    OneTimeBindingDirective,
    CsvUploadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2CompleterModule,
    MaterialModule,
    QuillModule,
    OverlayModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    QuillModule,
    Ng2CompleterModule,
    SpinnerComponent,    
    CustomTooltipDirective,
    OverlayModule,
    OneTimeBindingDirective,
    CsvUploadComponent
  ],
  providers:[
  ]
})
export class SharedModule { }
